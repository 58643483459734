import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from './pages/Login';
import "./styles.css";
import PlaceOrderPage from "./pages/PlaceOrder";
import DashboardPage from "./pages/Dashboard";
import OrganizationPage from "./pages/Organization";
import PaymentsPage from "./pages/Payments";
import OrdersPage from "./pages/Orders";
import Layout from "./layouts/Layout";
import CheckoutPage from "./pages/PlaceOrder/CheckoutPage";
import PrivateRoute from "./routes/PrivateRoute";
import ProfilePage from "./pages/Profile";
import ItemDetailsPage from "./pages/PlaceOrder/Item";
import AddAddress from "./pages/Organization/AddAddress";
import OrderDetailsPage from "./pages/Orders/OrderDetails";
import ForgotPasswordPage from "./pages/Login/ForgotPassword";
import PaymentDetailsPage from "./pages/Payments/PaymentDetails";
import AddLineItemsPage from "./pages/Orders/OrderDetails/AddLineItems";

function App() {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/reset-password" element={<ForgotPasswordPage />}></Route>
          <Route element={<Layout />}>
            <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>}></Route>
            <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>}></Route>
            <Route path="/place-order" element={<PrivateRoute><PlaceOrderPage /></PrivateRoute>}></Route>
            <Route path="/place-order/item" element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>}></Route>
            <Route path="/place-order/checkout" element={<PrivateRoute><CheckoutPage /></PrivateRoute>}></Route>
            <Route path="/organization" element={<PrivateRoute><OrganizationPage /></PrivateRoute>}></Route>
            <Route path="/organization/add-address" element={<PrivateRoute><AddAddress /></PrivateRoute>}></Route>
            <Route path="/payments" element={<PrivateRoute><PaymentsPage /></PrivateRoute>}></Route>
            <Route path="/payments/details" element={<PrivateRoute><PaymentDetailsPage /></PrivateRoute>}></Route>
            <Route path="/orders" element={<PrivateRoute><OrdersPage /></PrivateRoute>}></Route>
            <Route path="/orders/details/:orderID" element={<PrivateRoute><OrderDetailsPage /></PrivateRoute>}></Route>
            <Route path="/orders/details/:orderID/add-line-items" element={<PrivateRoute><AddLineItemsPage /></PrivateRoute>}></Route>
            <Route path="/orders/details/:orderID/add-line-items/item" element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>}></Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
