import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import ProductShoppingCard from '../../components/ProductShoppingCard';
import Button from '../../components/Button';
import ShoppingCartIcon from '../../assets/icons/ShoppingCart.svg';
import Bars3 from "../../assets/icons/Bars3.svg";
import BlankProfile from "../../assets/images/BlankProfile.png";

const MainHeader = ({ openSidemenu }) => {
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  const toggleShoppingCart = () => {
    if (isProfileMenuOpen) {
      setIsProfileMenuOpen(false)
    }
    setIsCartOpen(!isCartOpen)
  }

  const toggleProfileMenu = () => {
    if (isCartOpen) {
      setIsCartOpen(false)
    }
    setIsProfileMenuOpen(!isProfileMenuOpen)
  }

  const { shoppingCart, emptyCart } = useShoppingCart()

  const navigate = useNavigate()

  const pathname = useLocation().pathname

  useEffect(() => {
    setIsCartOpen(false)
    setIsProfileMenuOpen(false)
  }, [pathname])

  const { user, logout } = useAuth()

  return (
    <header className="flex items-center justify-end gap-8 h-20 bg-grey px-8">
      <button type='button' className='lg:hidden mr-auto w-10 h-10 p-1 rounded-lg hover:bg-grey-600' onClick={openSidemenu}>
        <img src={Bars3} alt="Burguer menu icon" />
      </button>
      <div className='relative w-9 flex items-center justify-center'>
        <button className='relative w-9 h-auto' onClick={toggleShoppingCart}>
          <img src={ShoppingCartIcon} alt='Shopping cart icon' className='w-9' />
          {
            shoppingCart.length > 0 && <div className='absolute -top-1 -right-1 flex items-center justify-center w-5 h-5 rounded-full bg-white text-xs font-semibold'>{shoppingCart.length}</div>
          }
        </button>
        <div className={`${!isCartOpen ? 'hidden' : 'flex'} flex-col items-stretch justify-start gap-3 absolute top-[110%] right-0 w-80 max-h-[30rem] p-4 rounded-lg bg-white border-2 border-black z-40 max-md:-right-20`}>
          <div className='flex items-end justify-between'>
            <h2 className='text-2xl font-bold'>Cart</h2>
            {
              shoppingCart.length > 0 && <Button value='clear cart' onClick={emptyCart} btnStyle='secondary' />
            }
          </div>
          <div className='flex flex-col items-stretch justify-start gap-2 pr-1 overflow-y-scroll' style={{ height: 'calc(100% - 2rem)' }}>
            {
              shoppingCart.length > 0
                ?
                  shoppingCart.map((product, index) => (
                    <ProductShoppingCard key={product.id} product={product} currentIndex={index} />
                  ))
                :
                  <div className='flex flex-col items-center justify-center'>
                    <p className='px-4 py-8 text-sm text-center'>Your shopping cart is empty</p>
                    <Button value='Go to shop' onClick={() => navigate('/place-order')} />
                  </div>
            }
          </div>
          {
            shoppingCart.length > 0 && <Button value='go to pay' onClick={() => navigate('/place-order/checkout')} />
          }
        </div>
      </div>
      <div className='relative'>
        <figure className="w-10 h-10 rounded-full border-2 border-black overflow-hidden"  onClick={toggleProfileMenu}>
          <img className='w-full h-full object-cover' src={user?.profile?.photo || BlankProfile} alt={`${user?.first_name} ${user?.last_name}`} />
        </figure>
        <div className={`${isProfileMenuOpen ? 'flex' : 'hidden'} absolute top-[110%] right-0 flex-col items-stretch justify-start gap-4 min-w-32 h-auto p-2 rounded-md bg-black z-40`}>
          <div>
            <p className='font-bold text-base text-white'>{user?.username}</p>
            <p className='font-semibold text-sm text-white'>{user?.email}</p>
          </div>
          <div className='flex flex-col items-start justify-start gap-1'>
            <Link to='/profile' className='font-semibold text-base text-white'>Profile</Link>
            <button className='font-semibold text-base text-white outline-none' onClick={logout}>Logout</button>
          </div>
        </div>
      </div>
    </header>
  );
}
 
export default MainHeader;