import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { DateBadge } from "../../components/Badges";
import InputGroup from "../../components/InputGroup";
import Button from "../../components/Button";
import BlankProfile from "../../assets/images/BlankProfile.png";
import ImagePlus from "../../assets/icons/ImagePlus.svg";
import XIcon from "../../assets/icons/XIcon.svg";
import InputPhoneNumber from "../../components/InputPhoneNumber";
import userService from "../../services/userService";

const ProfilePage = () => {
  const { user, logout } = useAuth()
  const [userDetails, setUserDetails] = useState(user);

  const [isEditing, setIsEditing] = useState(false);
  const [photoFile, setPhotoFile] = useState();
  const [newPhotoSelected, setNewPhotoSelected] = useState(false);
  const [profileData, setProfileData] = useState({
    username: userDetails.username,
    email: userDetails.email,
    first_name: userDetails.first_name,
    last_name: userDetails.last_name,
    second_last_name: userDetails.second_last_name,
    country_code: userDetails.country_code,
    phone_number_part: userDetails.phone_number_part
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalContainerOpen, setIsModalContainerOpen] = useState(false);

  const openModal = () => {
    setIsModalContainerOpen(true)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 10)
  }

  const imageContainer = useRef()
  const fileInput = useRef()

  const fetchUserDetails = async () => {
    const details = await userService.details()
    if (details) {
      setUserDetails(details)
    }
  }

  useEffect(() => {
    fetchUserDetails()
  }, [])

  useEffect(() => {
    if (userDetails) {
      setProfileData(prev => ({
        ...prev,
        username: userDetails.username,
        email: userDetails.email,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        second_last_name: userDetails.second_last_name,
        country_code: userDetails.country_code,
        phone_number_part: userDetails.phone_number_part
      }))
    }
  }, [userDetails])

  useEffect(() => {
    if (photoFile) {
      setNewPhotoSelected(true)
      const fileReader = new FileReader()
      const preview = imageContainer.current
      
      fileReader.onload = (e) => {
        preview.setAttribute('src', e.target.result)
      }
      fileReader.readAsDataURL(photoFile)
    } else {
      setNewPhotoSelected(false)
      const preview = imageContainer.current
      preview.setAttribute('src', userDetails.profile?.photo || BlankProfile)
    }
  }, [photoFile])

  const openFiles = () => {
    fileInput.current.click()
  }

  const resetPhoto = () => {
    setPhotoFile(null)
    fileInput.current.value = ''
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const updateProfile = async () => {
    const response = await userService.update(profileData)
    if (response) {
      setIsEditing(false)
      openModal()
    } else {
      console.log("Error")
    }

    // TODO: change photo
    // const formData = new FormData()
    // formData.append('photo', photoFile)
    // const photoResponse = await userService.updatePhoto(formData)
    // if (photoResponse) {
    //   console.log("Photo Changed")
    // } else {
    //   console.error("Error Changing Photo")
    // }
  }

  const cancelEdit = () => {
    setProfileData(prev => ({
      ...prev,
      username: userDetails.username,
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      second_last_name: userDetails.second_last_name,
      country_code: userDetails.country_code,
      phone_number_part: userDetails.phone_number_part
    }))
    setIsEditing(false)
    resetPhoto()
  }

  return (
    <div className="max-w-[50rem] m-auto">
      {/* // * MODAL */}
      <div className={`bg-transparent_black fixed top-0 left-0 h-dvh w-dvw p-4 ${isModalContainerOpen ? 'flex' : 'hidden'} items-center justify-center`}>
        <div className={`bg-white max-w-96 w-full p-4 rounded-lg flex flex-col items-stretch justify-start gap-4 scale-0 duration-200 ease-linear ${isModalOpen && 'scale-100'}`} onClick={e => e.stopPropagation()}>
          <h4 className="font-bold text-lg text-pretty">Profile Updated</h4>
          <p className="text-sm text-pretty">To see the changes, you need to log in again.</p>
          <div className="flex items-center justify-end gap-4 flex-wrap">
            <Button value="Accept" onClick={logout} />
          </div>
        </div>
      </div>
      <div className="flex items-end justify-between">
        <div className="flex flex-col items-start justify-between self-stretch">
          <div className="flex items-start justify-start gap-4">
            <DateBadge label="joined" date={userDetails.organizations[0].date_joined} />
            <DateBadge label="last login" date={userDetails.organizations[0].last_login} />
          </div>
          <h1 className="font-bold text-2xl">{`${userDetails.first_name} ${userDetails.last_name} ${userDetails.second_last_name || ''}`}</h1>
        </div>
        <div className="relative">
          <figure className="w-56 h-auto max-md:w-32 aspect-square bg-grey rounded-lg overflow-hidden">
            <img ref={imageContainer} className="w-full h-full object-cover" src={userDetails.profile?.photo || BlankProfile} alt={`${userDetails.first_name} ${userDetails.last_name}`} />
          </figure>
          <input ref={fileInput} onChange={(e) => setPhotoFile(e.target.files[0])} type="file" className="hidden" accept="image/png, image/jpeg, image/webp" />
          <div className={`${!isEditing && 'hidden'} absolute bottom-2 right-2 flex items-end justify-end gap-2`}>
            <div className={`${!newPhotoSelected && 'hidden'} h-6 w-6 rounded-full bg-grey-500 border-2 border-black cursor-pointer`} onClick={resetPhoto}>
              <img src={XIcon} alt="x icon" />
            </div>
            <div className="bottom-2 right-2 flex items-center justify-center h-10 w-10 rounded-full bg-grey-500 border-2 border-black cursor-pointer" onClick={openFiles}>
              <img src={ImagePlus} alt="" className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8 py-8 max-md:flex max-md:flex-col-reverse max-md:items-end">
        <section className="max-md:self-stretch flex flex-col items-stretch justify-start gap-4">
          <InputGroup
            id="username"
            name="username"
            label='username'
            value={profileData.username}
            isDisabled
          />
          <InputGroup
            id="email"
            name="email"
            type="email"
            label='email'
            value={profileData.email} 
            isDisabled
          />
          <InputGroup
            id="first_name"
            name="first_name"
            label='first name' 
            value={profileData.first_name}
            isDisabled={!isEditing}
            onChange={handleChange}
          />
          <InputGroup
            id="last_name"
            name="last_name"
            label='last name'
            value={profileData.last_name}
            isDisabled={!isEditing}
            onChange={handleChange}
          />
          <InputGroup
            id="second_last_name"
            name="second_last_name"
            label='second last name'
            value={profileData.second_last_name || ''}
            isDisabled={!isEditing}
            onChange={handleChange}
          />
          <InputPhoneNumber
            code={profileData.country_code}
            number={profileData.phone_number_part}
            isDisabled={!isEditing}
            onChange={handleChange}
          />
        </section>
        <section className="flex flex-col items-end justify-start">
          <h2 className="font-semibold text-xl">Organization</h2>
          <p className="font-bold text-2xl">{userDetails.organizations[0]?.organization_name}</p>
        </section>
      </div>
      <div className="flex items-end justify-end gap-4">
        {
          isEditing
            ?
              <>
                <Button value="Cancel" btnStyle="secondary" onClick={cancelEdit} />
                <Button value="Save" onClick={updateProfile} />
              </>
            :
              <Button value="Edit" onClick={() => setIsEditing(true)} />
        }
      </div>
    </div>
  );
}

export default ProfilePage;
