import api from "../utils/api"

const itemService = {
  list: async () => {
    try {
      const response = await api.get("/search/item/")
      return response.data.results;
    } catch (error) {
      console.error("Error fetching items!", error)
    }
  },
  itemDetails: async (itemID) => {
    try {
      const response = await api.get(`/admin/items/${itemID}/`)
      return response
    } catch (error) {
      console.error("Error fetching item details")
    }
  },
  listFiltered: async (filters) => {
    try {
      const response = await api.get(`/search/item/${filters}`)
      return response.data.results;
    } catch (error) {
      console.error("Error fetching items!", error)
    }
  },
  organizationPriceList: async (organization_id) => {
    try {
      const response = await api.get(`/crm/organization-price-list/?organization=${organization_id}`)
      return response.results
    } catch (error) {
      console.error("Error fetching Price List!", error)
    }
  },
  categoriesList: async () => {
    try {
      const response = await api.get("/inventory/categories")
      return response.data
    } catch (error) {
      console.error("Error fetchin categories.", error)
    }
  },
  warehousesList: async () => {
    try {
      const response = await api.get("/admin/warehouses/")
      return response.results
    } catch (error) {
      console.error("Error fetching warehouses", error)
    }
  },
  warehouseDetails: async (warehouseID) => {
    try {
      const response = await api.get(`/admin/warehouses/${warehouseID}/`)
      return response
    } catch (error) {
      console.error("Error fetching warehouse details", error)
    }
  }
}

export default itemService;
