import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import ordersService from "../../../services/ordersService";
import itemService from "../../../services/itemsService";
import addressesService from "../../../services/addressesService";
import { getFormattedDate } from "../../../utils/dateFormatter";
import { DateBadge, StatusBadge } from "../../../components/Badges";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderDetailsPDF from "./OrderDetailsPDF";
import Table, { TableCell } from "../../../components/Tables";
import Button from "../../../components/Button";
import TrashIcon from "../../../assets/icons/Trash.svg";
import XIcon from "../../../assets/icons/XIcon.svg";
import SelectInput from "../../../components/SelectInput";
import NotesInput from "../../../components/NotesInput";
import fulfillmentMethodsService from "../../../services/fulfillmentMethodsService";
import OrderDetailsLoader from "./OrderDetailsLoader";
import StateTimeline from "../../../components/StateTimeline";
import WarehouseRadioButton from "../../../components/WarehouseRadioButton";

const OrderDetailsPage = () => {
  const { orderID } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [adjustmentTypes, setAdjustmentTypes] = useState();
  const [warehousesList, setWarehousesList] = useState();
  const [warehouse, setWarehouse] = useState();
  const [originalWarehouse, setOriginalWarehouse] = useState();
  const [addresses, setAddresses] = useState();
  const [fulfillments, setFulfillments] = useState();
  const [notesNumber, setNotesNumber] = useState();
  const [notes, setNotes] = useState();

  const [originalOrderDetails, setOriginalOrderDetails] = useState(); // This state save the original data before edit the order
  const [lineItemsToDelete, setLineItemsToDelete] = useState([]);
  const [lineItemsTotal, setLineItemsTotal] = useState(0);
  const [adjustmentsTotal, setAdjustmentsTotal] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalContainerOpen, setIsModalContainerOpen] = useState(false);

  const openModal = () => {
    setIsModalContainerOpen(true)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 10)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      setIsModalContainerOpen(false)
    }, 200)
  }

  const fetchOrderDetails = async () => {
    setIsLoading(true)
    const orderResponse = await ordersService.details(orderID)

    if (orderResponse) {
      const newLineItems = JSON.parse(localStorage.getItem("newLineItems"))
      if (newLineItems) {
        if (newLineItems.order !== orderID) {
          localStorage.removeItem("newLineItems")
        } else {
          editOrder()
          setOrderDetails({
            ...orderResponse,
            order: {
              ...orderResponse.order,
              order_line_items_details: [
                ...orderResponse.order.order_line_items_details,
                ...newLineItems.items.map((newLineItem, index) => ({
                  item: newLineItem.id,
                  quantity: newLineItem.quantity,
                  name: newLineItem.name
                }))
              ]
            }
          })
        }
      } else {
        setOrderDetails(orderResponse)
      }
      
      setOriginalOrderDetails(orderResponse)

      const adjustmentsResponse = await ordersService.adjustmentTypes()
      setAdjustmentTypes(adjustmentsResponse)

      if (orderResponse.order.fulfillment_method === 4) { // Number 4 is the id of the fulfillment type 'Pick Up'
        const warehouseResponse = await itemService.warehouseDetails(orderResponse.order.order_line_items_details[0].warehouse)
        setWarehouse(warehouseResponse)
        setOriginalWarehouse(warehouseResponse)
      }

      setNotesNumber(Object.keys(orderResponse.order.notes || { 0: "" }).length)
      setNotes(Object.entries(orderResponse.order.notes || { 0: "" }).map(([key, value]) => {
        return {
          note: value
        }
      }) || [])

      const addressessResponse = await addressesService.organizationAddresses(user.organizations[0]?.organization)
      setAddresses(addressessResponse.map(address => {
        return {
          id: address.id,
          name: address.address_detail.location
        }
      }))

      const fulfillmentsResponse = await fulfillmentMethodsService.list()
      setFulfillments(fulfillmentsResponse)

      // Calculating Line Items Total
      setLineItemsTotal(orderResponse.order.order_line_items_details.reduce((total, lineItem) => {
        return total + (lineItem.quantity * lineItem.price)
      }, 0))

      // Calculating Adjustments Total
      setAdjustmentsTotal(orderResponse.order.adjustments_detail.reduce((total, adjustment) => {
        return total + parseFloat(adjustment.amount)
      }, 0))

      setIsLoading(false)
    } else {
      setOrderNotFound(true)
      setIsLoading(false)
    }
  }

  const notesHandleChange = (index, event) => {
    const newNotes = [...notes]
    newNotes[index].note = event.target.value
    setNotes(newNotes)
    setOrderDetails(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        notes: newNotes.reduce((notesObj, note, i) => {
          notesObj[i] = note.note
          return notesObj
        }, {})
      }
    }))
  }

  const addNote = () => {
    setNotesNumber(notesNumber + 1)
    setNotes(prevNotes => ([
      ...prevNotes,
      {
        note: ""
      }
    ]))
  }

  const removeNote = (index) => {
    setNotesNumber(notesNumber - 1)
    const newNotes = notes.filter((_, noteIndex) => noteIndex !== index)
    setNotes(newNotes)
    setOrderDetails(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        notes: newNotes.reduce((notesObj, note, i) => {
          notesObj[i] = note.note
          return notesObj
        }, {})
      }
    }))
  }

  const deleteLineItem = (index) => {
    if (!lineItemsToDelete.includes(index)) {
      if (orderDetails.order.order_line_items_details[index].item) {
        setLineItemsToDelete(prev => [...prev, index])
        setOrderDetails(prevPayload => ({
          ...prevPayload,
          order: {
            ...prevPayload.order,
            order_line_items_details: prevPayload.order.order_line_items_details.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  quantity: originalOrderDetails.order.order_line_items_details[index]?.quantity || 0
                }
              }
              return item
            })
          }
        }))
      } else {
        setOrderDetails(prevDetails => ({
          ...prevDetails,
          order: {
            ...prevDetails.order,
            order_line_items_details: [
              ...prevDetails.order.order_line_items_details.slice(0, index),
              ...prevDetails.order.order_line_items_details.slice(index + 1)
            ]
          }
        }))
      }
    }
  }

  const resetLineItem = (index) => {
    setLineItemsToDelete(prev => prev.filter(value => value !== index))
  }

  const handleLineItems = (event, index) => {
    const { name, value } = event.target
    setOrderDetails(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        order_line_items_details: prevPayload.order.order_line_items_details.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              [name]: name === "quantity" ? ((value === '' || !Number(value)) ? 0 : parseInt(value)) : parseInt(value)
            }
          }
          return item
        })
      }
    }))
  }

  const handleOrderData = (event) => {
    const { name, value } = event.target
    setOrderDetails(prevPayload => {
      const newOrder = {
        ...prevPayload.order,
        [name]: parseInt(value)
      }

      if (name === "fulfillment_method" && parseInt(value) === 4) {
        newOrder.shipping_address = null
      }

      return {
        ...prevPayload,
        order: newOrder
      }
    })
  }

  const fetchWarehouses = async () => {
    if (!warehousesList) {
      const warehousesResponse = await itemService.warehousesList()
      if (warehousesResponse) {
        setWarehousesList(warehousesResponse)
      }
    }
  }

  const editOrder = () => {
    fetchWarehouses()
    setOriginalOrderDetails(orderDetails)
    setIsEditing(true)
  }

  const cancelOrderEdit = () => {
    localStorage.removeItem('newLineItems')
    setLineItemsToDelete([])
    setNotesNumber(Object.keys(originalOrderDetails.order.notes || { 0: "" }).length)
    setNotes(Object.entries(originalOrderDetails.order.notes || { 0: "" }).map(([key, value]) => {
      return {
        note: value
      }
    }) || [])
    if (orderDetails.order.fulfillment_method === 4 && originalWarehouse) {
      setWarehouse(originalWarehouse)
    }
    setOrderDetails(originalOrderDetails)
    setIsEditing(false)
  }

  const updateOrder = async () => {
    setIsEditing(false)
    setIsLoading(true)

    const payload = {
      fulfillment_method: orderDetails.order.fulfillment_method,
      shipping_address: orderDetails.order.shipping_address,
      billing_address: orderDetails.order.billing_address,
      notes: orderDetails.order.notes,
      order_line_items: orderDetails.order.order_line_items_details.filter((li, index) => !lineItemsToDelete.includes(index))
        .map(lineItem => {
          if (lineItem.id) {
            return {
              id: lineItem.id,
              item: lineItem.item,
              quantity: parseInt(lineItem.quantity),
              warehouse: orderDetails.order.fulfillment_method === 4 ? warehouse.id : lineItem.warehouse || null
            }
          }
          return {
            item: lineItem.item,
            quantity: parseInt(lineItem.quantity),
            warehouse: orderDetails.order.fulfillment_method === 4 ? warehouse.id : lineItem.warehouse || null
          }
        })
    }

    const response = await ordersService.update(orderDetails.order.id, payload)
    if (response) {
      setIsLoading(false)
      localStorage.removeItem("newLineItems")
      window.location.reload()
    }
  }

  const deleteOrder = async () => {
    const deleted = await ordersService.delete(orderDetails.id)
    if (deleted) {
      navigate("/orders")
    }
  }

  useEffect(() => {
    fetchOrderDetails()
  }, [])

  return (
    isLoading
      ?
        <OrderDetailsLoader />
      :
        orderNotFound
          ?
            <div className="flex flex-col items-center justify-center gap-8 h-full">
              <p className="font-semibold text-xl">Order With ID #{orderID} Not Found</p>
              <p>Verify if the order ID is correct or go back to the <Link to={"/orders"} className="text-primary">order list</Link>.</p>
            </div>
          :
            orderDetails && <div className="flex flex-col items-stretch justify-start gap-6">
              {/* // * DELETE ORDER MODAL */}
              <div className={`bg-transparent_black fixed top-0 left-0 h-dvh w-dvw p-4 ${isModalContainerOpen ? 'flex' : 'hidden'} items-center justify-center`} onClick={closeModal}>
                <div className={`bg-white max-w-96 w-full p-4 rounded-lg flex flex-col items-stretch justify-start gap-4 scale-0 duration-200 ease-linear ${isModalOpen && 'scale-100'}`} onClick={e => e.stopPropagation()}>
                  <h4 className="font-bold text-lg text-pretty">Are you sure you want to delete this order (#{orderDetails.id})?</h4>
                  <p className="text-sm text-pretty">Deleting this order is a permanent action and cannot be undone.</p>
                  <div className="flex items-center justify-end gap-4 flex-wrap">
                    <Button btnStyle="secondary" value="Keep order" onClick={closeModal} />
                    <Button btnStyle="danger" value="Delete" onClick={deleteOrder} />
                  </div>
                </div>
              </div>
              {/* // * ORDER DETAILS */}
              <div className="flex items-end justify-between">
                <div className="flex items-start justify-start gap-8">
                  <DateBadge label="created" date={getFormattedDate(orderDetails.order.created)} />
                  <DateBadge label="updated" date={getFormattedDate(orderDetails.order.updated)} />
                </div>
                <p className="font-medium text-xs">Order ID: <span className="font-semibold text-xl">{orderDetails.id}</span></p>
              </div>
              <div className="flex items-center justify-between gap-4">
                <StatusBadge isActive={orderDetails.order.approval} text={orderDetails.order.approval ? 'Approved' : 'Not Approved'} />
                {
                  !isEditing &&
                    <PDFDownloadLink
                      document={<OrderDetailsPDF order={originalOrderDetails} adjustmentTypes={adjustmentTypes} warehouseToPickup={warehouse} />}
                      fileName={`Order_Details_${orderDetails.id}`}
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderRadius: '.5rem',
                        paddingInline: '1rem',
                        paddingBlock: '.375rem',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px'
                      }}
                    >
                      Download PDF
                    </PDFDownloadLink>
                }
              </div>
              <div className="flex flex-col items-end justify-start gap-4 overflow-x-scroll">
                <Table
                  headers={['items', 'unit price', 'quantity', `${isEditing ? 'actions' : 'total'}`]}
                  className="min-w-[48rem] self-stretch"
                >
                  {
                    Array.from({ length: orderDetails.order.order_line_items_details.length }).map((_, index) => (
                      <tr key={index} className={`${!isEditing && 'odd:bg-grey'}`}>
                        <TableCell>
                          <input
                            name="item"
                            className={`min-w-80 w-full outline-none ${!isEditing ? 'bg-transparent appearance-none opacity-100' : 'px-2 py-1 bg-grey rounded-lg'} ${lineItemsToDelete.includes(index) && 'opacity-40'}`}
                            value={orderDetails.order.order_line_items_details[index].item_detail?.name || orderDetails.order.order_line_items_details[index]?.name}
                            readOnly
                          />
                        </TableCell>
                        <TableCell className="w-40">{!isEditing && `$${orderDetails.order.order_line_items_details[index].price}`}</TableCell>
                        <TableCell className="w-40">
                          <input
                            name="quantity"
                            value={parseInt(orderDetails.order.order_line_items_details[index].quantity)}
                            className={`outline-none w-full ${!isEditing ? 'bg-transparent appearance-none' : 'px-2 py-1 bg-grey rounded-lg'} ${lineItemsToDelete.includes(index) && 'opacity-40'}`}
                            disabled={!isEditing || lineItemsToDelete.includes(index)}
                            onChange={(e) => handleLineItems(e, index)}
                          />
                        </TableCell>
                        <TableCell>
                          {
                            isEditing
                              ?
                                <div className="flex items-center justify-center">
                                  <button
                                    className="h-7 w-7 p-1"
                                    type="button"
                                    onClick={() => {
                                      if (lineItemsToDelete.includes(index)) {
                                        resetLineItem(index)
                                      } else {
                                        deleteLineItem(index)
                                      }
                                    }}
                                  >
                                    <img
                                      src={lineItemsToDelete.includes(index) ? XIcon : TrashIcon}
                                      alt="buton icon"
                                    />
                                  </button>
                                </div>
                              :
                                `$${orderDetails.order.order_line_items_details[index].total_price?.toFixed(2)}`
                          }
                        </TableCell>
                      </tr>
                    ))
                  }
                  {
                    !isEditing &&
                      <tr>
                        <td></td>
                        <td className="px-2.5 py-1.5 bg-grey-600 font-semibold text-lg">Totals</td>
                        <td className="px-2.5 py-1.5 bg-grey-600">
                          {
                            orderDetails.order.order_line_items_details.reduce((acc, lineItem) => {
                              return acc + parseInt(lineItem.quantity)
                            }, 0)
                          }
                        </td>
                        <td className="px-2.5 py-1.5 bg-grey-600">${lineItemsTotal.toFixed(2)}</td>
                      </tr>
                  }
                </Table>
                {
                  isEditing && <Button value="add items" btnStyle="secondary" onClick={() => navigate('add-line-items')} />
                }
              </div>
              {
                !isEditing &&
                  <>
                    <div className="flex flex-col items-end justify-start gap-2">
                      <h2 className="font-semibold text-xl">Adjustments</h2>
                      <table>
                        <tbody>
                          {
                            orderDetails.order.adjustments_detail.length > 0
                              ?
                                Array.from({ length: orderDetails.order.adjustments_detail.length }).map((_, index) => (
                                  <tr key={index}>
                                    <td className="text-right text-sm font-semibold pr-2 capitalize">{adjustmentTypes?.find(type => type.id === orderDetails.order.adjustments_detail[index].adjustment_type)?.name}:</td>
                                    <td className="text-lg">${orderDetails.order.adjustments_detail[index].amount}</td>
                                  </tr>
                                ))
                              :
                                <tr>
                                  <td><p className="text-sm">This order has no adjustments.</p></td>
                                </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="flex flex-col items-end justify-start">
                      <p className="font-semibold text-xl bg-grey px-4 py-2 rounded-lg">Total: <span className="font-bold text-2xl">${(lineItemsTotal + adjustmentsTotal)?.toFixed(2)}</span></p>
                      <table>
                        <tbody>
                          {
                            orderDetails.order.ieps !== 0 && (
                              <tr>
                                <td className="text-right text-sm font-semibold pr-2 capitalize">IEPS:</td>
                                <td className="text-lg">${orderDetails.order.ieps.toFixed(2)}</td>
                              </tr>
                            )
                          }
                          {
                            orderDetails.order.iva !== 0 && (
                              <tr>
                                <td className="text-right text-sm font-semibold pr-2 capitalize">IVA:</td>
                                <td className="text-lg">${orderDetails.order.iva.toFixed(2)}</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
              }
              <div className="flex items-end justify-between gap-4 max-sm:flex-col max-sm:items-stretch">
                <div className="flex flex-col items-stretch justify-start gap-4 max-w-[30rem] w-full">
                  <SelectInput
                    id="fulfillment"
                    options={fulfillments}
                    name="fulfillment_method"
                    label="fulfillment method"
                    value={orderDetails.order.fulfillment_method}
                    isDisabled={!isEditing}
                    onChange={handleOrderData}
                  />
                  {
                    orderDetails.order.fulfillment_method !== 4 &&
                      <SelectInput
                        id="shipping_address"
                        options={addresses}
                        name="shipping_address"
                        label="shipping address"
                        value={orderDetails.order.shipping_address || 0}
                        isDisabled={!isEditing}
                        onChange={handleOrderData}
                      />
                  }
                  <SelectInput
                    id="billing_address"
                    options={addresses}
                    name="billing_address"
                    label="billing address"
                    value={orderDetails.order.billing_address}
                    isDisabled={!isEditing}
                    onChange={handleOrderData}
                  />
                  <NotesInput
                    label="notes"
                    inputsNumber={notesNumber}
                    notes={notes}
                    handleChange={notesHandleChange}
                    addNote={addNote}
                    removeNote={removeNote}
                    isDisabled={!isEditing}
                  />
                </div>
                {
                  warehouse && orderDetails.order.fulfillment_method === 4 &&
                    <div className="flex flex-col items-start justify-start gap-2">
                      <p className="font-semibold text-base">The order pickup will be at:</p>
                      <div className="max-w-[25rem]">
                        <p className="font-semibold text-sm">{warehouse.name}</p>
                        <p className="text-sm">{warehouse.address.location}</p>
                      </div>
                    </div>
                }
              </div>
              {
                orderDetails.order.fulfillment_method === 4 && isEditing &&
                  <div>
                    <h2 className="font-semibold text-sm mb-2">Choose where the order will be picked up:</h2>
                    <div className="grid gap-8 max-h-96 overflow-y-scroll" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))' }}>
                      {
                        warehousesList?.map((wh) => (
                          <WarehouseRadioButton
                            key={wh.id}
                            warehouse={wh}
                            isSelected={warehouse?.id === wh.id}
                            onClick={() => setWarehouse(wh)}
                          />
                        ))
                      }
                    </div>
                  </div>
              }
              {
                !isEditing && (
                  <div className="flex flex-col items-start justify-start gap-2">
                    <h2 className="font-semibold text-xl">Fulfillment State History</h2>
                    <StateTimeline states={orderDetails.order.fulfillment_state_history} />
                  </div>
                )
              }
              {
                !orderDetails.order.approval
                  &&
                    <div className="flex items-end justify-end gap-4">
                      {
                        isEditing
                          ?
                            <>
                              <Button value="Cancel" btnStyle="secondary" onClick={cancelOrderEdit} />
                              <Button value="Delete" btnStyle="danger" onClick={openModal} />
                              <Button value="Save" onClick={updateOrder} />
                            </>
                          :
                            <Button value="Edit" onClick={editOrder} />
                      }
                    </div>
              }
            </div>
  );
}

export default OrderDetailsPage;
