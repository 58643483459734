import { useLocation, useNavigate } from 'react-router-dom'
import ArrowLeft from '../../../assets/icons/ArrowLeft.svg'
import ChevronLeft from '../../../assets/icons/ChevronLeft.svg'
import ChevronRight from '../../../assets/icons/ChevronRight.svg'
import { useEffect, useState } from 'react'
import Button from '../../../components/Button'
import { useShoppingCart } from '../../../context/ShoppingCartContext'
import defaultImage from '../../../assets/images/ImageError.png'

const ItemDetailsPage = () => {
  const location = useLocation()
  const { item, fromOrder } = location.state

  const navigate = useNavigate()

  const [imageIndex, setImageIndex] = useState(0)
  const [imgLoaded, setImgLoaded] = useState(false);
  
  const images = item.images

  useEffect(() => {
    const img = new Image()
    img.src = images[imageIndex]

    img.onload = () => {
      setImgLoaded(true)
    }
    img.onerror = () => {
      setImgLoaded(false)
    }
  }, [imageIndex])

  const nextImage = () => {
    if (imageIndex < images.length - 1) {
      setImageIndex(imageIndex + 1)
    } else {
      setImageIndex(0)
    }
  }

  const previousImage = () => {
    if (imageIndex > 0) {
      setImageIndex(imageIndex - 1)
    } else {
      setImageIndex(images.length - 1)
    }
  }

  const { addToCart, shoppingCart } = useShoppingCart()

  const [isInCart, setIsInCart] = useState(false)
  const [quantityInCart, setQuantityInCart] = useState(0)

  useEffect(() => {
    const inCart = shoppingCart.some(product => product.id === item.id)
    setIsInCart(inCart)

    if (inCart) {
      const product = shoppingCart.find(product => product.id === item.id)
      setQuantityInCart(product.quantity)
    }
  }, [shoppingCart])

  return (
    <>
      <section className="flex items-center justify-start gap-8">
        <button
          className="flex items-center justify-center w-8 h-8 p-1 rounded-full hover:bg-grey"
          onClick={() => navigate(-1)}
        >
          <img
            src={ArrowLeft}
            alt="Black arrow pointing to the left indicating that it is a button to return to the previous page."
            className="w-full h-full object-cover"
          />
        </button>
        <h3 className="font-bold text-2xl">Item Details</h3>
      </section>
      <section className='grid grid-cols-2 gap-4 pt-8 max-md:flex max-md:flex-col'>
        <div className='flex flex-col items-center justify-start gap-4 w-full max-w-96 p-4 m-auto rounded-lg bg-grey'>
          <figure className='w-full h-[26rem] bg-white rounded-md overflow-hidden'>
            <img
              className='w-full h-full object-cover'
              src={imgLoaded ? images[imageIndex] : defaultImage}
              alt={imgLoaded ? item.name : "Logistika Urbana logo in white on a blue background"}
            />
          </figure>
          <div className={`${images.length > 1 ? 'flex' : 'hidden'} items-center justify-center gap-8`}>
            <button className='px-1 py-1.5 rounded hover:bg-grey-600' onClick={previousImage}>
              <img src={ChevronLeft} alt='Chevron left icon' />
            </button>
            <button className='px-1 py-1.5 rounded hover:bg-grey-600' onClick={nextImage}>
              <img src={ChevronRight} alt='Chevron right icon' />
            </button>
          </div>
        </div>
        <div className='flex flex-col items-start justify-between gap-4'>
          <div className='flex flex-col items-start justify-start gap-4'>
            <h1 className='font-bold text-2xl'>{item.name}</h1>
            <p>{item.description}</p>
            <p className='font-semibold text-lg'>Price: <span className='font-bold text-xl'>${item.organization_price || item.default_price}</span></p>
            <div>
              <p className='font-semibold text-lg'>Categories:</p>
              <ul>
                {
                  item.category.slug.length > 0
                    ?
                      item.category.slug.map((category, index) => (
                        <li key={index} className='capitalize'>{category}</li>
                      ))
                    :
                      <li>This item has no assigned categories</li>
                }
              </ul>
            </div>
          </div>
          <div className='self-end'>
            {
              !fromOrder && (
                isInCart
                ?
                  <p className='font-semibold text-sm'>There are currently {quantityInCart} in your shopping cart</p>
                :
                  item.status === "active"
                    ?
                      <Button
                        value='Add to cart'
                        onClick={() => addToCart({
                          ...item,
                          quantity: 1
                        })}
                      />
                    :
                      <p className='font-semibold text-sm'>This product is not currently available</p>
              )
            }
          </div>
        </div>
      </section>
    </>
  )
}
 
export default ItemDetailsPage