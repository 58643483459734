import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useShoppingCart } from "../../../context/ShoppingCartContext"
import ProductShoppingCard from "../../../components/ProductShoppingCard"
import Button from "../../../components/Button"
import "./styles.css" 
import RadioButton from "../../../components/RadioButton"
import SelectInput from "../../../components/SelectInput"
import BagIcon from "../../../assets/icons/BagIcon.svg"
import ShoppingCartIcon from "../../../assets/icons/ShoppingCart.svg"
import Table, { TableCell } from "../../../components/Tables"
import NotesInput from "../../../components/NotesInput"
import fulfillmentMethodsService from "../../../services/fulfillmentMethodsService"
import { useAuth } from "../../../context/AuthContext"
import addressesService from "../../../services/addressesService"
import ordersService from "../../../services/ordersService"
import itemService from "../../../services/itemsService"
import WarehouseRadioButton from "../../../components/WarehouseRadioButton"
import styles from "../../../components/styles/tableLoader.module.css"

const STEPS = {
  LINEITEMS: 'lineItems',
  ORDERDATA: 'orderData',
  SUMMARY: 'summary',
}

const CheckoutPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [orderResponse, setOrderResponse] = useState(null)
  const [step, setStep] = useState(STEPS.LINEITEMS);
  const [fulfillments, setFulfillments] = useState();
  const [addresses, setAddresses] = useState();
  const [warehouses, setWarehouses] = useState();
  const [warehouseSelected, setWarehouseSelected] = useState({});
  const [adjustmentTypes, setAdjustmentTypes] = useState();
  const [orderDrafted, setOrderDrafted] = useState();
  const [lineItemsTotal, setLineItemsTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const { user } = useAuth()
  const { shoppingCart, emptyCart } = useShoppingCart()

  const [newOrderPayload, setNewOrderPayload] = useState({
    organization: user.organizations[0]?.organization,
    order: {
      fulfillment_method: 0,
      shipping_address: 0,
      billing_address: 0,
      approval: false,
      notes: null,
      order_line_items: shoppingCart.map(item => ({
        item: item.id,
        quantity: item.quantity
      }))
    }
  });

  useEffect(() => {
    fetchFulfillments()
    fetchAddresses()
    fetchWarehouses()
    fetchAdjustmentTypes()
  }, [])

  useEffect(() => {
    if (newOrderPayload.order.fulfillment_method === 4) {
      setNewOrderPayload(prevPayload => ({
        ...prevPayload,
        order: {
          ...prevPayload.order,
          shipping_address: null
        }
      }))
    } else {
      setNewOrderPayload(prevPayload => ({
        ...prevPayload,
        order: {
          ...prevPayload.order,
          shipping_address: 0
        }
      }))
    }
  }, [newOrderPayload.order.fulfillment_method]);

  const fetchAdjustmentTypes = async () => {
    const adjustments = await ordersService.adjustmentTypes()
    setAdjustmentTypes(adjustments)
  }

  const fetchWarehouses = async () => {
    const response = await itemService.warehousesList()
    if (response) {
      setWarehouses(response)
    }
  }

  const fetchFulfillments = async () => {
    const response = await fulfillmentMethodsService.list()
    const fulfillmentsFiltered =response.filter(fm => fm.slug === 'pickup' || fm.slug === 'ship')
    console.log(fulfillmentsFiltered)
    setFulfillments(fulfillmentsFiltered)
  }

  const fetchAddresses = async () => {
    const response = await addressesService.organizationAddresses(user.organizations[0]?.organization)
    setAddresses(response.map(address => ({
      id: address.id,
      name: `${address.address_detail.street}, ${address.address_detail.exterior}, ${address.address_detail.interior}, ${address.address_detail.city}`
    })))
  }

  const [inputsNumber, setInputsNumber] = useState(1);

  const [notes, setNotes] = useState([{ note: '' }]);

  const notesHandleChange = (index, event) => {
    const newNotes = [...notes]
    newNotes[index].note = event.target.value
    setNotes(newNotes)
    setNewOrderPayload(prev => ({
      ...prev,
      order: {
        ...prev.order,
        notes: newNotes.reduce((notesObj, current, index) => {
          notesObj[index] = current.note
          return notesObj
        }, {})
      }
    }))
  }

  const addNote = () => {
    setInputsNumber(inputsNumber + 1)
    setNotes([...notes, { note: '' }])
  }

  const removeNote = (index) => {
    setInputsNumber(inputsNumber - 1)
    const newNotes = notes.filter((_, noteIndex) => noteIndex !== index)
    setNotes(newNotes)
    setNewOrderPayload(prev => ({
      ...prev,
      order: {
        ...prev.order,
        notes: newNotes.reduce((notesObj, current, index) => {
          notesObj[index] = current.note
          return notesObj
        }, {})
      }
    }))
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setNewOrderPayload(prevPayload => ({
      ...prevPayload,
      order: {
        ...prevPayload.order,
        [name]: parseInt(value)
      }
    }))
  }

  const createOrder = async () => {
    setIsLoading(true)
    try {
      // ? { state: 1 } '1' is the id corresponding to the 'order created' state
      const response = await ordersService.updateFulfillmentHistory({ order: orderDrafted.order.id, state: 1 })
      if (response) {
        setOrderResponse({ message: "Order place", order: orderDrafted })
        emptyCart()
      }
    } catch (error) {
      console.error('Order Error:', error)
      setOrderResponse({ message: 'Order failed', error: error.message })
    } finally {
      setIsLoading(false)
    }
  }

  const createDraftOrder = async () => {
    let draftPayload = JSON.parse(JSON.stringify(newOrderPayload))
    if (newOrderPayload.order.fulfillment_method === 4) {
      draftPayload.order.order_line_items.map(line => line.warehouse = warehouseSelected.id)
    }
    setIsLoading(true)
    try {
      const response = await ordersService.create(draftPayload)
      if (response) {
        setOrderDrafted(response)
        setStep(STEPS.SUMMARY)
      }
    } catch (error) {
      console.error("Error saving draft", error)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDraftOrder = async () => {
    const response = await ordersService.delete(orderDrafted.id)
    if (response) {
      setOrderDrafted(null)
    }
  }

  const navigate = useNavigate()

  const [goNext, setGoNext] = useState(false);

  useEffect(() => {
    if (shoppingCart.length > 0) {
      if (newOrderPayload.order.fulfillment_method !== 4) {
        if (newOrderPayload.order.shipping_address !== 0 && newOrderPayload.order.billing_address !== 0) {
          setGoNext(true)
        } else {
          setGoNext(false)
        }
      } else {
        if (newOrderPayload.order.billing_address !== 0 && warehouseSelected.id) {
          setGoNext(true)
        } else {
          setGoNext(false)
        }
      }
    } else if (orderResponse) {
      setStep(STEPS.SUMMARY)
    } else {
      setStep(STEPS.LINEITEMS)
    }
  }, [newOrderPayload, shoppingCart, orderResponse, warehouseSelected])

  useEffect(() => {
    setNewOrderPayload(prev => ({
      ...prev,
      order: {
        ...prev.order,
        order_line_items: shoppingCart.map(item => ({
          item: item.id,
          quantity: item.quantity
        }))
      }
    }))
  }, [shoppingCart])

  useEffect(() => {
    if (orderDrafted) {
      const itemsTotal = orderDrafted.order.order_line_items_details.reduce((total, lineItem) => {
        return total + parseFloat(lineItem.price) * parseInt(lineItem.quantity)
      }, 0)

      const adjustmentsTotal = orderDrafted.order.adjustments_detail.reduce((total, adjustment) => {
        return total + parseFloat(adjustment.amount)
      }, 0)

      setLineItemsTotal(itemsTotal)

      setFinalTotal(itemsTotal + adjustmentsTotal)
    }
  }, [orderDrafted])

  const stepPage = () => {
    switch (step) {
      case STEPS.LINEITEMS:
        return (
          <>
            <section className="flex items-center justify-start">
              <h1 className="font-bold text-2xl">Checkout</h1>
            </section>
            <section className="flex items-start justify-between gap-8 max-w-[60rem] pt-4 max-md:flex-col max-md:justify-start" style={{ height: 'calc(100% - 2rem)' }}>
              <div className="flex-shrink-0 flex flex-col items-stretch justify-start gap-4 max-h-full pr-2 overflow-y-scroll max-md:h-auto max-md:w-full">
                {
                  shoppingCart.length > 0
                    ?
                      shoppingCart.map((product, index) => (
                        <ProductShoppingCard key={product.id} product={product} currentIndex={index} isCheckoutCard />
                      ))
                    :
                      <div className='flex items-center justify-center gap-8'>
                        <p className='px-4 py-8 text-sm text-center'>Your shopping cart is empty</p>
                        <Button value='Go to shop' onClick={() => navigate('/place-order')} />
                      </div>
                }
              </div>
              <div className="flex flex-col items-end justify-between gap-4 min-h-3/6 max-h-full h-fit max-md:self-end">
                <div className="flex flex-col items-end justify-end gap-8">
                  <p className=" flex items-center justify-between gap-4 font-semibold text-xl px-5 py-4 bg-grey rounded-lg w-fit">
                    TOTAL: 
                    <span className="font-bold text-2xl">
                      $
                      {
                        shoppingCart.reduce((total, item) => {
                          return total + (item.quantity * (item.organization_price || item.default_price))
                        }, 0)
                      }
                    </span>
                  </p>
                  {
                    shoppingCart.length > 0 && <Button value="Next" onClick={() => setStep(STEPS.ORDERDATA)} />
                  }
                </div>
              </div>
            </section>
          </>     
        )
      case STEPS.ORDERDATA:
        return (
          !isLoading
            ?
              <>
                <section className="flex items-center justify-start gap-8">
                  <h1 className="font-bold text-2xl">Checkout</h1>
                </section>
                <section className="flex flex-col items-stretch justify-start gap-4 pt-4" style={{ height: 'calc(100% - 2rem)' }}>
                  <div className="grid grid-cols-2 gap-16 max-md:flex max-md:flex-col max-md:gap-8">
                    <div className="flex flex-col items-stretch justify-start gap-4">
                      <div className="flex flex-col items-start justify-start">
                        <p className="font-semibold text-sm">Fulfillment Method:</p>
                        <div className="flex items-center justify-center gap-8">
                          {
                            fulfillments?.map(method => (
                              <RadioButton
                                key={method.id}
                                id={method.id}
                                name="fulfillment_method"
                                label={method.name}
                                value={method.id}
                                checked={newOrderPayload.order.fulfillment_method === method.id}
                                onChange={handleChange}
                                isBtnType
                              />
                            ))
                          }
                        </div>
                      </div>
                      <SelectInput
                        id="billing_address"
                        label="billing Address"
                        name="billing_address"
                        options={addresses}
                        value={newOrderPayload.order.billing_address}
                        onChange={handleChange}
                      />
                      {
                        newOrderPayload.order.fulfillment_method !== 4 &&
                          <SelectInput
                            id="shipping_address"
                            label="shipping Address"
                            name="shipping_address"
                            options={addresses}
                            value={newOrderPayload.order.shipping_address}
                            onChange={handleChange}
                          />
                      }
                    </div>
                    <div className="flex flex-col items-stretch justify-start gap-8">
                      <NotesInput
                          label="notes"
                          inputsNumber={inputsNumber}
                          notes={notes}
                          handleChange={notesHandleChange}
                          addNote={addNote}
                          removeNote={removeNote}
                        />
                    </div>
                  </div>
                  {
                    newOrderPayload.order.fulfillment_method === 4 &&
                      <div>
                        <h2 className="font-semibold text-sm mb-2">Choose where the order will be picked up:</h2>
                        <div className="grid gap-8 max-h-96 overflow-y-scroll" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))' }}>
                          {
                            warehouses.map((warehouse) => (
                              <WarehouseRadioButton
                                key={warehouse.id}
                                warehouse={warehouse}
                                isSelected={warehouseSelected.id === warehouse.id}
                                onClick={() => setWarehouseSelected(warehouse)}
                              />
                            ))
                          }
                        </div>
                      </div>
                  }
                  <div className="flex items-center justify-end gap-4">
                    <Button value="back" btnStyle="secondary" onClick={() => setStep(STEPS.LINEITEMS)} />
                    <Button value="next" onClick={() => {
                      createDraftOrder()
                    }} isDisabled={!goNext} />
                  </div>
                </section>
              </>
            :
              <div className="flex flex-col items-stretch justify-start gap-6">
                <div className={`${styles.loader} h-56`} />
                <div className="flex items-end justify-end">
                  <div className={`${styles.loader} h-48 max-w-80 w-full`} />
                </div>
                <div className={`${styles.loader} h-60 max-w-96 w-full`} />
              </div>
        )
      case STEPS.SUMMARY:
        return (
          <>
            {
              !isLoading && !orderResponse && (
                <div className="flex flex-col items-stretch justify-start gap-6">
                  <div>
                    <h1 className="font-bold text-2xl">Summary</h1>
                  </div>
                  <div className="overflow-x-scroll">
                    <Table headers={['item', 'unit price', 'quantity', 'total']} className="min-w-[48rem]">
                      {
                        orderDrafted?.order?.order_line_items_details.map((product, index) => (
                          <tr className="odd:bg-grey" key={index}>
                            <TableCell>{product.item_detail.name}</TableCell>
                            <TableCell>{`$${product.price}`}</TableCell>
                            <TableCell>{parseInt(product.quantity)}</TableCell>
                            <TableCell>{`$${product.total_price.toFixed(2)}`}</TableCell>
                          </tr>
                        ))
                      }
                      <tr>
                        <td></td>
                        <td className="px-2.5 py-1.5 bg-grey-600 font-semibold text-lg">Totals</td>
                        <td className="px-2.5 py-1.5 bg-grey-600">
                          {
                            orderDrafted?.order.order_line_items_details.reduce((total, lineitem) => {
                              return total + parseInt(lineitem.quantity)
                            }, 0)
                          }
                        </td>
                        <td className="px-2.5 py-1.5 bg-grey-600">${lineItemsTotal.toFixed(2)}</td>
                      </tr>
                    </Table>
                  </div>
                  <div className="flex flex-col items-end justify-start gap-2">
                    <h2 className="font-semibold text-xl">Adjustments</h2>
                    <table>
                      <tbody>
                        {
                          orderDrafted?.order.adjustments_detail.length > 0
                            ?
                              orderDrafted?.order.adjustments_detail.map((adjustment, index) => (
                                <tr key={index}>
                                  <td className="text-right text-sm font-semibold pr-2 capitalize">{adjustmentTypes?.find(type => type.id === adjustment.adjustment_type)?.name}:</td>
                                  <td className="text-lg">${adjustment.amount}</td>
                                </tr>
                              ))
                            :
                              <tr>
                                <td><p className="text-sm">This order has no adjustments.</p></td>
                              </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="flex items-center justify-end">
                    <p className="font-semibold text-xl bg-grey px-4 py-2 rounded-lg">Total: <span className="font-bold text-2xl">${finalTotal.toFixed(2)}</span></p>
                  </div>
                  <div className="flex items-end justify-between gap-4 max-sm:flex-col max-sm:items-stretch">
                    <div className="flex flex-col items-stretch justify-start gap-4 max-w-[30rem] w-full">
                      <SelectInput
                        id="fulfillment"
                        options={fulfillments}
                        name="fulfillment_method"
                        label="fulfillment method"
                        value={orderDrafted?.order.fulfillment_method}
                        isDisabled
                      />
                      {
                        newOrderPayload.order.fulfillment_method !== 4 && (
                          <SelectInput
                            id="shipping_address"
                            options={addresses}
                            name="shipping_address"
                            label="shipping address"
                            value={orderDrafted?.order.shipping_address}
                            isDisabled
                          />
                        )
                      }
                      <SelectInput
                        id="billing_address"
                        options={addresses}
                        name="billing_address"
                        label="billing address"
                        value={orderDrafted?.order.billing_address}
                        isDisabled
                      />
                      <NotesInput
                        label="notes"
                        inputsNumber={inputsNumber}
                        notes={notes}
                        handleChange={notesHandleChange}
                        addNote={addNote}
                        removeNote={removeNote}
                        isDisabled
                      />
                    </div>
                    {
                      newOrderPayload.order.fulfillment_method === 4 &&
                        <div className="flex flex-col items-start justify-start gap-2">
                          <p className="font-semibold text-base">The order pickup will be at:</p>
                          <div className="max-w-[25rem]">
                            <p className="font-semibold text-sm">{warehouseSelected.name}</p>
                            <p className="text-sm">{warehouseSelected.address.location}</p>
                          </div>
                        </div>
                    }
                  </div>
                  <div className="flex items-center justify-end gap-4">
                    <Button value="back" btnStyle="secondary" onClick={() => {
                        deleteDraftOrder()
                        setStep(STEPS.ORDERDATA)
                      }}
                    />
                    <Button value="place order" onClick={createOrder} />
                  </div>
                </div>
              )
            }
            {
              isLoading && (
                <div className="flex flex-col items-center justify-center gap-4" style={{ height: 'calc(100% - 5rem)' }}>
                  <figure className="w-60 h-auto">
                    <img src={ShoppingCartIcon} alt="Shopping cart icon" className="moving-image" />
                  </figure>
                  <p className="font-bold text-3xl">Creating your order...</p>
                </div>
              )
            }
            {
              orderResponse && (
                <div className="flex flex-col items-center justify-center gap-4" style={{ height: 'calc(100% - 5rem)' }}>
                  <figure className="w-60 h-auto">
                    <img src={BagIcon} alt="Bag icon" />
                  </figure>
                  <p className="font-bold text-3xl">{orderResponse.message}</p>
                  <p className="font-bold text-3xl">Thanks for ordering!</p>
                  <div className="flex items-center justify-center gap-8">
                    <Button value="accept" btnStyle="secondary" onClick={() => navigate('/orders')} />
                    <Button value="Go to details" onClick={() => navigate(`/orders/details/${orderDrafted.id}`)} />
                  </div>
                </div>
              )
            }
          </>
        )
      default:
        return setStep(STEPS.LINEITEMS)
    }
  }

  return stepPage()
}
 
export default CheckoutPage