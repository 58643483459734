import React, { createContext, useContext, useEffect, useState } from 'react';

const ShoppingCartContext = createContext()

export const ShoppingCartProvider = ({ children }) => {
  const [shoppingCart, setShoppingCart] = useState([])

  useEffect(() => {
    const cartSaved = localStorage.getItem('shoppingCart')
    if (cartSaved) {
      setShoppingCart(JSON.parse(cartSaved))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart))
  }, [shoppingCart])

  const addToCart = (product) => {
    setShoppingCart(prevCart => {
      const productExists = prevCart.some(item => item.id === product.id)

      if (productExists) {
        const productIndex = prevCart.findIndex(item => item.id === product.id)

        const updatedCart = prevCart.map((item, index) => {
          if (productIndex === index) {
            return {
              ...item,
              quantity: item.quantity + product.quantity
            }
          }
          return item
        })
        return updatedCart
      }

      return [...prevCart, product]
    })
  }

  const deleteFromCart = (index) => {
    const newCart = [...shoppingCart]
    newCart.splice(index, 1)
    setShoppingCart(newCart)
  }

  const emptyCart = () => {
    setShoppingCart([])
  }

  return (
    <ShoppingCartContext.Provider value={{ shoppingCart, addToCart, deleteFromCart, emptyCart, setShoppingCart }}>
      {children}
    </ShoppingCartContext.Provider>
  )
}

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext)
}