import api from "../utils/api";

const userService = {
  details: async () => {
    try {
      const response = await api.get("/user/wholesale-user/")
      return response.data
    } catch (error) {
      console.error("Error fetching user data.", error)
    }
  },
  update: async (payload) => {
    try {
      const response = await api.put("/user/wholesale-user/", payload)
      return response
    } catch (error) {
      console.error("Error updating user.", error)
    }
  },
  updatePhoto: async (payload) => {
    try {
      const response = await api.patch("/user/wholesale-user/", payload)
      return response
    } catch (error) {
      console.error("Error updating photo.", error)
    }
  }
}

export default userService;
