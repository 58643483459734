import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from "../../components/InputGroup";
import Table, { TableCell, TableContainer } from "../../components/Tables";
import financeService from "../../services/financeService";
import { getFormattedDate } from "../../utils/dateFormatter";
import InputRange from "../../components/InputRange";
import { RadioGroup } from "../../components/RadioGroup";
import { useAuth } from "../../context/AuthContext";
import InfoIcon from "../../assets/icons/InfoIcon.svg";
import TableLoader from "../../components/TableLoader";

const PaymentsPage = () => {
  const [payments, setPayments] = useState();
  const [paymentsFiltered, setPaymentsFiltered] = useState(payments);
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tableHeaders, setTableHeaders] = useState(['ID', 'amount', 'allocated', 'available', 'type', 'added', 'details']);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (windowWidth < 768) {
      setTableHeaders(['ID', 'allocated', 'available', 'added', 'details'])
    } else {
      setTableHeaders(['ID', 'amount', 'allocated', 'available', 'type', 'added', 'details'])
    }
  }, [windowWidth])

  const { user } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    fetchPayments()
  }, [pageSize, page])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (payments) {
      setPaymentsFiltered(payments)
    }
  }, [payments])

  const updatePagination = (newPage, newSize) => {
    setPage(newPage)
    setPageSize(newSize)
  }

  const fetchPayments = async () => {
    setIsLoading(true)
    const response = await financeService.organizationPayments(user.organizations[0]?.organization, pageSize, page)
    if (response) {
      setPayments(response.results)
      setTotalPages(response.meta.page_total)
      setPageSize(response.meta.page_size)
      setIsLoading(false)
    } else {
      setPayments([])
      setTotalPages(1)
      setIsLoading(false)
    }
  }

  const filtersInitialState = {
    minAvailable: 0,
    maxAvailable: 10000,
    payment_type: "all",
    date_from: "",
    date_to: "",
  };

  const [filters, setFilters] = useState(filtersInitialState);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const [minAvailable, setMinAvailable] = useState(filters.minAvailable);
  const [maxAvailable, setMaxAvailable] = useState(filters.maxAvailable);

  useEffect(() => {
    if (minAvailable !== filters.minAvailable || maxAvailable !== filters.maxAvailable) {
      setFilters(prevFilters => ({
        ...prevFilters,
        minAvailable: minAvailable,
        maxAvailable: maxAvailable
      }))
    }
  }, [minAvailable, maxAvailable])

  const [paymentTypeSelected, setPaymentTypeSelected] = useState("all");

  const paymentTypes = [
    {
      label: 'all',
      value: 'all'
    },
    {
      label: 'card',
      value: 'card'
    },
    {
      label: 'cash',
      value: 'cash'
    },
    {
      label: 'transfer',
      value: 'transfer'
    }
  ]

  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters,
      payment_type: paymentTypeSelected
    }))
  }, [paymentTypeSelected])

  const filterFields = (
    <>
      <InputRange
        id="amount_available"
        name="amount_available"
        label="amount available"
        minRange={filtersInitialState.minAvailable}
        maxRange={filtersInitialState.maxAvailable}
        minValue={filters.minAvailable}
        maxValue={filters.maxAvailable}
        setMinValue={setMinAvailable}
        setMaxValue={setMaxAvailable}
      />
      <RadioGroup
        label="payment type"
        options={paymentTypes}
        selectedOption={filters.payment_type}
        onChange={setPaymentTypeSelected}
      />
      <div>
        <span className="capitalize text-sm font-semibold">Created:</span>
        <InputGroup
          id="date_from"
          name="date_from"
          label="from"
          type="date"
          value={filters.date_from}
          onChange={handleChange}
        />
        <InputGroup
          id="date_to" 
          name="date_to"
          label="to"
          type="date"
          value={filters.date_to}
          onChange={handleChange}
        />
      </div>
    </>
  )

  const filterPayments = () => {
    let filtered = payments;

    if (filters.maxAvailable !== filtersInitialState.maxAvailable || filters.minAvailable !== filtersInitialState.minAvailable) {
      filtered = filtered.filter(obj => obj.payment.remaining_amount >= minAvailable && obj.payment.remaining_amount <= maxAvailable)
    }
    if (filters.payment_type !== "all") {
      filtered = filtered.filter(obj => obj.payment.payment_type.toLowerCase() === filters.payment_type)
    }
    if (filters.date_from) {
      filtered = filtered.filter(obj => new Date(obj.payment.created) >= new Date(filters.date_from))
    }
    if (filters.date_to) {
      filtered = filtered.filter(obj => new Date(obj.payment.created) <= new Date(filters.date_to))
    }

    setPaymentsFiltered(filtered)
  }

  const resetFilters = () => {
    setFilters(filtersInitialState)
    setPaymentsFiltered(payments)
  }

  return (
    <TableContainer
      filterFields={filterFields}
      filtersInitialState={filtersInitialState}
      filters={filters}
      setFilters={setFilters}
      applyFilters={filterPayments}
      resetFilters={resetFilters}
      showAddButton={false}
      totalPages={totalPages}
      updatePagination={updatePagination}
      pageSizes={[20, 50, 100]}
    >
      <Table headers={tableHeaders}>
        {
          paymentsFiltered?.length > 0 && !isLoading
            ?
              paymentsFiltered?.map((obj, index) => (
                <tr className="odd:bg-grey" key={index}>
                  <TableCell>{obj.id}</TableCell>
                  {
                    windowWidth >= 768 && <TableCell>${obj.payment.amount}</TableCell>
                  }
                  <TableCell>${(obj.payment.amount - obj.payment.remaining_amount).toFixed(2)}</TableCell>
                  <TableCell>${obj.payment?.remaining_amount?.toFixed(2)}</TableCell>
                  {
                    windowWidth >= 768 && <TableCell><span className="capitalize">{obj.payment.payment_type}</span></TableCell>
                  }
                  <TableCell>{getFormattedDate(obj.payment.created)}</TableCell>
                  <TableCell>
                    <div className="flex items-center justify-center">
                      <button
                        className="h-8 w-8 p-1"
                        onClick={() => navigate('details', { state: { payment: obj } })}>
                        <img src={InfoIcon} alt="Icon for information" />
                      </button>
                    </div>
                  </TableCell>
                </tr>
              ))
            :
              <tr>
                <td colSpan={tableHeaders.length}>
                  {
                    !isLoading
                      ?
                      <div className="flex flex-col items-center justify-center gap-4 p-8">
                        <p className="font-semibold text-lg">No payments found</p>
                        <p className="text-sm">{payments?.length !== 0 ? 'Try changing or resetting the filters' : 'There was an error loading the payments'}</p>
                      </div>
                      :
                      <TableLoader />
                  }
                </td>
              </tr>
        }
      </Table>
    </TableContainer>
  );
}
 
export default PaymentsPage;